


:host {
    display: block;
    left: 0%;
    right: 0%;
    top: 0%;
    margin: 0% !important;
    padding: 0% !important;
}

body {
    margin: 0% !important;
    padding: 0% !important;
    font-family: Trebuchet MS, sans-serif;
}

.header{
    background-color: #339caa; 
    color: #fff;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 4em;
    margin: 0% !important;
    padding: 0% !important;
    font-family: Trebuchet MS, sans-serif;
}
.footer{
    background-color: #339caa; 
    color: #fff;
    text-align: center;
    width: 100%;
    height: 2em;
    margin: 0% !important;
    padding: 0% !important;
}

.cuerpo {
    background-color:#fff;
    font-family: Trebuchet MS, sans-serif;
    height: 100%;
    margin-top: 4em;
    margin-bottom: 2em;
    margin-left: 0.5%;
    text-align: justify;
}

.card{
    background-color:#c2dceeb6 !important;
    height: 95%;
    width: 100%;
    margin-top: 4em;
    margin-bottom: 2em;
    margin-left: 2.2%;
    margin-right: 0.5%;

}

.btn-white{
    background-color: #fff;
    color: #1e6e18;
    border: #1e6e18;
    width: 100%;
}

.btn-green{
    background-color: #339caa;
    color: #fff;
    border: #18616e;
    width: 100%;
    
}
.btn-flotante{
	background-color: #339caa;
    color: #fff;
    border: #18616e;
    width: 12%;
	position: fixed;
    right: 480px;
    border-radius: 3px; 
    
	
}
.btn-flotante2{
	background-color: #339caa;
    color: #fff;
    border: #18616e;
    width: 12%;
	position: fixed;
    right: 280px;
    border-radius: 3px; 
    
	
}
.btn-flotante3{
    background-color: #339caa;
    color: #fff;
    border: #18616e;
    width: 12%;
	position: fixed;
    right: 80px;
    border-radius: 3px; 
   
	
	
}
.btn-greenL{
    background-color: #339caa;
    color: #fff;
    border: #042002;
    width: 100%;
}
.btn-seccion{
    background-color: #fff;
    border: none;
    width: 100%;
}
.flotante {
    display: scroll;
    position: fixed;
    height: 78%;
    right: 40px;
    width: 45%;
    margin-top: 2em;
    margin-bottom: 6em;
}

.flotante2 { 
    display: scroll;
    position: fixed;
    height: 85%;
    right: 40px;
    width: 45%;
    margin-top: 2em;
    margin-bottom: 6em;
}
.color {
    background-color: #69adb65e;
    color: rgb(14, 12, 12);
}
hr{
    background-color: #1e6e18;
    border-style:solid;
}

.btn-menu{
    background-color:#339caad2;
    color: black;
    width: 100%;
    height: 70px;
    margin: 10px;
}

.btn-menu-admin{
    background-color:#339caad2;
    color: black;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    padding: 0px;
}